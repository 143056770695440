<template>
  <div class="main-container">
    <div class="logout-button" @click="goLogout">退出登录</div>
    <el-carousel 
      indicator-position="none" 
      :height="$px2vw(1080)+'vw'" 
      :interval="15000"
      :loop="true"
      ref="carousel"
      :pause-on-hover="false"
      :initial-index="2"
    >
      
      <el-carousel-item>
        <div class="item-container">
          <img class="item-go-left" src="@/assets/images/arrow/1-left.png" @click="$refs.carousel.setActiveItem(2)" />
          <img class="yhxwyszpt" src="@/assets/images/云和小微园数字平台.jpg" />
          <img class="item-go-right" src="@/assets/images/arrow/1-right.png" @click="$refs.carousel.setActiveItem(1)" />
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="item-container">
          <img class="item-go-left" src="@/assets/images/arrow/2-left.png" @click="$refs.carousel.setActiveItem(0)" />
          <!-- <iframe class="iframe-container" src="http://t3y.top:8686/activation" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe> -->
          <iframe class="iframe-container" src="https://yunhezt.toys-trades.com/activation" allowfullscreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe>
          
          <img class="item-go-right" src="@/assets/images/arrow/2-right.png" @click="$refs.carousel.setActiveItem(2)" />
        </div>
      </el-carousel-item>
      <el-carousel-item>
        <div class="item-container">
          <img class="item-go-left" src="@/assets/images/arrow/3-left.png" @click="$refs.carousel.setActiveItem(1)" />
          <Index></Index>
          <img class="item-go-right" src="@/assets/images/arrow/3-right.png" @click="$refs.carousel.setActiveItem(0)" />
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import Index from "./index.vue";
import { goLogin } from "@/libs/login.js";
export default {
  name: "mainIndex",
  components: {
    Index
  },
  data() {
    return {
      
    };
  },
  mounted() {
    
  },
  methods: {
    goLogout(){
      goLogin();
    }
  },
  filters: {

  },
};
</script>
<style lang="scss" scoped>
.yhxwyszpt{
  width: 1920px;
  height: 1080px;
}
.iframe-container{
  width: 1920px;
  height: 1080px;
  border: medium none;
}
.main-container {
  position: relative;
  min-height: 100vh;
  background-color: #000511;
  ::v-deep .el-carousel__arrow{
    display: none;
  }
  .logout-button{
    position: absolute;
    width: 95px;
    height: 34px;
    background: #002951;
    border: 1px solid #5CCBFF;

    font-size: 14px;
    font-weight: normal;
    color: #5CCBFF;

    display: flex;
    align-items: center;
    justify-content: center;

    top: 10px;
    right: 28px;
    z-index: 1000;

    cursor: pointer;
  }
}
.item-container{
  height: 1080px;
  // width: 1920px;
  width: 100%;
  position: relative;
  .item-go-left{
    width: 82px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1000;
  }
  .item-go-right{
    width: 82px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1000;
  }
}
</style>